<template>
  <b-card
    bg-variant="light"
    class="text--black"
  >
    <b-overlay :show="loading">
      <b-form
        inline
        class="justify-content-center"
      >
        <b-form-group
          label="Industry"
          label-cols="auto"
          class="mx-3 mt-2"
          :disabled="loading"
        >
          <b-form-select
            v-model="form.industry_id"
            :options="industries"
            size="sm"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group
          label="Year"
          label-cols="auto"
          class="mx-3 mt-2"
          :disabled="loading"
        >
          <b-form-select
            v-model="form.year"
            :options="years"
            size="sm"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group
          label="Quarter"
          label-cols="auto"
          class="mx-3 mt-2"
          :disabled="loading"
        >
          <b-form-select
            v-model="form.quarter"
            :options="quarters"
            size="sm"
            required
            >
          </b-form-select>

        </b-form-group>

        <b-form-group
          label="Bucket"
          label-cols="auto"
          class="mx-3 mt-2"
          :disabled="loading"
          v-if="bucketsForIndustry && bucketsForIndustry.length > 0"
        >
          <b-form-select
            v-model="form.industryrevenuebucket_id"
            :options="bucketsForIndustry"
            size="sm"
          >
            <template #first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-form>
      <div v-if="form.companies && form.companies.length > 0">
        <AvailableCompanies
          :companies="form.companies"
          @update="(val) => { form.companies = val }"
        ></AvailableCompanies>
      </div>
      <div class="mt-4" v-if="form.companies && form.companies.length > 0">
        <b-button type="button" variant="success" :disabled="!hasCompanySelected" @click="generateReport">
          Generate Report
        </b-button>
        <b-button class="ml-4" type="button" variant="info" :disabled="!hasCompanySelected" @click="exportCSV">
          <b-icon icon="download"></b-icon> Export
        </b-button>
      </div>

      <div v-if="form && record">
        <b-card
          no-body
          class="mt-3"
        >
          <b-card-header>
            <b-card-title class="text-left m-0">{{ record.industry.name }} Industry Averages for {{ form.year }}<span v-if="form.quarter"> Q{{ form.quarter }}</span></b-card-title>
          </b-card-header>

          <b-table
            v-if="record.averages.length"
            sticky-header
            :items="record.averages"
            :fields="fieldsaverages"
            outlined
            bordered
            small
            striped
            hover
            class="mb-0 small"
          >
            <template #cell(total)="data">
              {{ Math.abs(data.item.total) | currency }}
            </template>
            <template #cell(percentofincome)="data">
              {{ data.item.percentofincome }}%
            </template>
          </b-table>
        </b-card>
      </div>
    </b-overlay>
  </b-card>
</template>
<script>
import IndustryService from '@/services/IndustryService'
import AvailableCompanies from '@/components/admin/industry/AvailableCompanies'

export default {
  name: 'IndustryAverageEdit',

  components: { AvailableCompanies },

  data () {
    return {
      loading: false,
      form: {
        industry_id: null,
        industryrevenuebucket_id: null,
        year: null,
        quarter: null,
        companies: []
      },
      record: null,
      industries: null,
      buckets: [],
      years: [],
      quarters: [1, 2, 3, 4],
      fieldscompanies: [
        { key: 'id', label: '', sortable: false, tdClass: 'align-middle' },
        { key: 'company', sortable: true, thClass: 'text-left', tdClass: 'align-middle text-left' },
        { key: 'income', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' },
        { key: 'expense', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' }
      ],
      fieldsaverages: [
        { key: 'accounttype', label: 'Account Type', sortable: true, tdClass: 'align-middle' },
        { key: 'accountname', label: 'Account', sortable: true, thClass: 'text-left', tdClass: 'align-middle text-left' },
        { key: 'total', label: 'Average', sortable: true, thClass: 'text-right', tdClass: 'align-middle text-right' },
        { key: 'percentofincome', label: '% of Income', sortable: true, tdClass: 'align-middle' }
      ]
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      IndustryService.init().then((response) => {
        if (response.data.result) {
          this.industries = []
          this.buckets = []
          response.data.info.industries.forEach((i) => {
            this.industries.push({ value: +i.id, text: i.name })
            this.buckets.push({ industry_id: +i.id, buckets: i.buckets })
          })
        }

        const dt = new Date()
        const y = dt.getFullYear() - 5
        for (let i = y; i <= dt.getFullYear(); i++) {
          this.years.push(i)
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    loadCompanies () {
      if (!this.form.industry_id || !this.form.year) {
        return
      }

      this.loading = true
      this.form.companies = []
      IndustryService.loadCompanies(this.form).then((response) => {
        if (response.data.result) {
          this.form.companies = response.data.info.companies
        } else {
          console.dir(response.data)
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    generateReport () {
      if (this.loading || !this.hasCompanySelected) {
        return false
      }

      this.loading = true

      const data = { ...this.form }
      data.total = true

      IndustryService.generateReport(data).then((response) => {
        if (response.data.result) {
          this.record = response.data.info
        } else {
          this.$aimNotify.error(response, 'error', 'Report Failed', 'Failed to generate report')
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    exportCSV () {
      if (this.loading || !this.hasCompanySelected) {
        return false
      }

      this.loading = true

      const data = { ...this.form }

      IndustryService.generateReport(data).then((response) => {
        if (response.data.result) {
          let csvContent = 'data:text/csv;charset=utf-8,Company,SBS Bookkeeper,'
          const headers = []
          response.data.info.accounts.forEach(acct => {
            headers.push(acct.accountname)
          })
          csvContent += headers.map(str => str === null ? '' : `"${str}"`).join(',') + '\n'
          response.data.info.averages.forEach(comp => {
            const row = []
            row.push(comp.company)
            row.push(comp.bookkeeper)
            response.data.info.accounts.forEach(acct => {
              if (comp[acct.accountname]) {
                row.push((acct.accountname === 'Net Income' && comp[acct.accountname] < 0 ? '-$' : '$') + (Math.round(Math.abs(comp[acct.accountname]) * 100) / 100))
              } else {
                row.push('$0.00')
              }
            })
            csvContent += row.map(str => str === null ? '' : `"${str}"`).join(',') + '\n'
          })
          const csv = encodeURI(csvContent)
          const dwnld = document.createElement('a')
          dwnld.setAttribute('href', csv)
          dwnld.setAttribute('download', response.data.info.industry.name + (this.form.year ? '_' + this.form.year : '') + (this.form.quarter ? '_Q' + this.form.quarter : '') + '.csv')
          document.body.appendChild(dwnld)
          dwnld.click()
        } else {
          this.$aimNotify.error(response, 'error', 'Report Failed', 'Failed to generate report')
        }
      }).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => {
        this.loading = false
      })
    },

    resetBucket () {
      if (!this.bucketsForIndustry.some(e => (+e.value === +this.form.industryrevenuebucket_id))) {
        this.form.industryrevenuebucket_id = null
        return true
      }

      return false
    }
  },

  watch: {
    form: {
      handler () {
        this.record = null
      },
      deep: true
    },
    'form.industry_id' (n, o) {
      if (n !== o) {
        if (!this.resetBucket()) {
          this.loadCompanies()
        }
      }
    },
    'form.year' (n, o) {
      if (n !== o) {
        this.loadCompanies()
      }
    },
    'form.quarter' (n, o) {
      if (n !== o) {
        this.loadCompanies()
      }
    },
    'form.industryrevenuebucket_id' (n, o) {
      if (n !== o) {
        this.loadCompanies()
      }
    }
  },

  computed: {
    hasCompanySelected () {
      return this.form.companies.some(e => e.selected)
    },

    bucketsForIndustry () {
      if (this.form && this.form.industry_id) {
        const buckets = []
        this.buckets.some(e => {
          if (+e.industry_id === +this.form.industry_id) {
            e.buckets.forEach(b => {
              const minTxt = this.$convertToCurrency(b.min, false)
              const maxTxt = this.$convertToCurrency(b.max, false)
              buckets.push({ value: +b.id, text: (b.max ? minTxt + ' - ' + maxTxt : minTxt + '+') })
            })
            return true
          }
          return false
        })

        return buckets
      }

      return []
    }
  }
}
</script>
